import isNaN from 'lodash/isNaN';
import { DateTime, DateTimeFormatOptions, DurationUnits } from 'luxon';

import { Channel } from '@cca/types';

import {
  Profile,
  SalesLineLabel,
  channelToProfile,
  profileSalesLineLabelMapping,
} from '../environment';
import { toDateTime } from '../general';

type FormatRelativeChangeOptions = {
  dense?: boolean;
  withSign?: boolean;
  decimals?: number;
};

export const DEFAULT_DATETIME_FORMAT: DateTimeFormatOptions = {
  ...DateTime.DATETIME_MED,
  hour12: false,
};

export const DATETIME_CHART_FORMAT: DateTimeFormatOptions = {
  month: '2-digit',
  day: '2-digit',
  hour12: false,
};

export const DATETIME_CHART_HOURS_FORMAT: DateTimeFormatOptions = {
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

export const DATE_PICKER_DATETIME_FORMAT = 'MMM d, yyyy, T';

export function formatRelativeChange(
  value: number,
  {
    dense = false,
    withSign = true,
    decimals = 2,
  }: FormatRelativeChangeOptions = {},
): string {
  const safeValue = noNaN(value);
  const space = dense ? '' : ' ';
  const sign = withSign ? (safeValue < 0 ? '-' : '+') : '';
  const fixedValue = (Math.abs(safeValue) * 100).toFixed(decimals);
  if (safeValue === 0) {
    return `${fixedValue}${space}%`;
  }
  return dense ? `${fixedValue}${space}%` : `${sign}${fixedValue}${space}%`;
}

export function formatShare(value: number, decimals?: number): string {
  return formatRelativeChange(value, {
    withSign: false,
    decimals: decimals ?? 0,
    dense: true,
  });
}

export function formatDateTime(dateValue: string | Date | DateTime): string {
  const date = toDateTime(dateValue);
  return date.toLocaleString(DEFAULT_DATETIME_FORMAT);
}
export function formatDateTimeChart(
  dateValue: string | Date | DateTime,
): string {
  const date = toDateTime(dateValue);
  return date.toLocaleString(DATETIME_CHART_FORMAT);
}
export function formatDateTimeHoursChart(
  dateValue: string | Date | DateTime,
): string {
  const date = toDateTime(dateValue);
  return date.toLocaleString(DATETIME_CHART_HOURS_FORMAT);
}

export function formatChannel(channel: Channel): string {
  const profile = channelToProfile(channel);
  return profile ? formatProfile(profile) : '<no-profile>';
}

export function formatProfile(profile: Profile): string {
  return `${profile.substring(0, 2)}-${profile.substring(2)}`.toUpperCase();
}

export function formatTimeDifference(
  created: string | Date,
  displayed: string | Date,
  unit: DurationUnits = 'seconds',
): string {
  const createdDate = toDateTime(created);
  const displayedDate = toDateTime(displayed);

  return createdDate.diff(displayedDate, unit).seconds.toFixed(2);
}

export function noNaN(value: number, fallback = 0): number {
  return isNaN(value) ? fallback : value;
}

export function formatSalesLine(channel: Channel): SalesLineLabel {
  return profileSalesLineLabelMapping[channelToProfile(channel)];
}
