import { Profile } from '@cca/util';

import { Environment } from '../../environment.types';

const environment: Environment = {
  isDevelopment: false,
  isQa: false,
  isProduction: true,

  profile: Profile.mmde,

  chatbotUrl: 'https://chatbot.mediamarkt.de',
  integrationUrl: 'https://chatbot.mediamarkt.de/integration',
  middlewareUrl: 'https://chatbot.mediamarkt.de/api',
  theme: 'mm',
  showWelcomeMessage: true,
  welcomeMessageOptions: {
    delayInSeconds: 3,
    minTimeDistanceInHours: 1,
  },
};

export default environment;
