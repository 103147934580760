import { Env, EnvironmentMode } from './types';

const environmentMap: Record<Env, string> = {
  [EnvironmentMode.development]: '.env',
  [EnvironmentMode.qa]: '.env.qa.local',
  [EnvironmentMode.production]: '.env.production.local',
  local: '.env',
};

export function selectDotenv(environment: Env): string {
  return environmentMap[environment];
}
