import environment from 'environment';
import { fetch as fetchPolyfill } from 'whatwg-fetch';

(async () => {
  const fetch =
    typeof window.fetch !== 'undefined' ? window.fetch : fetchPolyfill;
  const response = await fetch(environment.middlewareUrl + '/cache-tag');
  const { tag } = await response.json();

  const script = document.createElement('script');
  script.src =
    environment.integrationUrl + '/cca-chatbot-integration.js?' + tag;
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href =
    environment.integrationUrl + '/cca-chatbot-integration.css?' + tag;

  document.head.appendChild(link);
  document.body.appendChild(script);
})();
