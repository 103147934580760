import { Profile } from '@cca/util';

import { Environment } from '../../environment.types';

const environment: Environment = {
  isDevelopment: true,
  isQa: false,
  isProduction: false,

  profile: Profile.sede,

  chatbotUrl: 'https://dev-chatbot.saturn.de',
  integrationUrl: 'https://dev-chatbot.saturn.de/integration',
  middlewareUrl: 'https://dev-chatbot.saturn.de/api',
  theme: 'se',
  showWelcomeMessage: false,
  welcomeMessageOptions: {
    delayInSeconds: 3,
    minTimeDistanceInHours: 1,
  },
};

export default environment;
