import { Profile } from '@cca/util';

import { Environment } from '../../environment.types';

const environment: Environment = {
  isDevelopment: false,
  isQa: true,
  isProduction: false,

  profile: Profile.mmbe,

  chatbotUrl: 'https://qa-chatbot.mediamarkt.be',
  integrationUrl: 'https://qa-chatbot.mediamarkt.be/integration',
  middlewareUrl: 'https://qa-chatbot.mediamarkt.be/api',
  theme: 'mm',
  showWelcomeMessage: true,
  welcomeMessageOptions: {
    delayInSeconds: 3,
    minTimeDistanceInHours: 1,
  },
};

export default environment;
