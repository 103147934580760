import { mapValues } from 'lodash';
import { groupBy } from 'ramda';

import { Channel } from '@cca/types';

import { assertAllProfilesDefined } from './assert-all-profiles-defined';
import { profileToChannel } from './profile-to-channel';
import { ProfileChannelMapping } from './types';

export enum Subsidiary {
  DE = 'DE',
  ES = 'ES',
  AT = 'AT',
  NL = 'NL',
  BE = 'BE',
}
export enum SalesLine {
  media = 'media',
  saturn = 'saturn',
}

export enum SalesLineLabel {
  MediaMarkt = 'MediaMarkt',
  Saturn = 'Saturn',
}

export enum Profile {
  mmde = 'mmde',
  sede = 'sede',
  mmes = 'mmes',
  mmat = 'mmat',
  mmnl = 'mmnl',
  mmbe = 'mmbe',
}

export enum ProfilePrefix {
  mm = 'mm',
  se = 'se',
}

export enum ProfileColor {
  Color0 = 0,
  Color1 = 1,
  Color2 = 2,
  Color3 = 3,
  Color4 = 4,
  Color5 = 5,
}

export enum Language {
  de = 'de',
  es = 'es',
  nl = 'nl',
  fr = 'fr',
}

export enum Locale {
  deDE = 'de-DE',
  deAT = 'de-AT',
  esES = 'es-ES',
  nlNL = 'nl-NL',
  nlBE = 'nl-BE',
  frBE = 'fr-BE',
}

export enum BotName {
  Emmi = 'Emmi',
  Sammy = 'Sammy',
}

export enum TimeZone {
  EurBerlin = 'Europe/Berlin',
  EurVienna = 'Europe/Vienna',
  EurMadrid = 'Europe/Madrid',
  EurStockholm = 'Europe/Stockholm',
}

export enum OnlineOutletId {
  mmde = 1125,
  sede = 1015,
  mmes = 1119,
  mmat = 718,
  mmnl = 742,
  mmbe = 1241,
  // mmch = 1132,
  // mmtr = 1259,
  // mmhu = 726,
  // mmit = 2116,
}

export enum Country {
  Germany = 'Germany',
  Austria = 'Austria',
  Spain = 'Spain',
  Sweden = 'Sweden',
  Netherlands = 'Netherlands',
  Belgium = 'Belgium',
}

assertAllProfilesDefined(OnlineOutletId);

const preProfileChannelMapping: Record<Profile, Channel> = {
  mmde: {
    subsidiary: Subsidiary.DE,
    salesLine: SalesLine.media,
  },
  sede: {
    subsidiary: Subsidiary.DE,
    salesLine: SalesLine.saturn,
  },
  mmes: {
    subsidiary: Subsidiary.ES,
    salesLine: SalesLine.media,
  },
  mmat: {
    subsidiary: Subsidiary.AT,
    salesLine: SalesLine.media,
  },
  mmnl: {
    subsidiary: Subsidiary.NL,
    salesLine: SalesLine.media,
  },
  mmbe: {
    subsidiary: Subsidiary.BE,
    salesLine: SalesLine.media,
  },
} as const;

export const profileColorMapping: Record<Profile, ProfileColor> = {
  mmde: ProfileColor.Color0,
  sede: ProfileColor.Color1,
  mmes: ProfileColor.Color2,
  mmat: ProfileColor.Color3,
  mmnl: ProfileColor.Color4,
  mmbe: ProfileColor.Color5,
};

export const profileChannelMapping: Record<Profile, ProfileChannelMapping> =
  mapValues(preProfileChannelMapping, (value, key) => ({
    profile: key as Profile,
    color: profileColorMapping[key as Profile],
    ...value,
  }));

export const profileLocaleMapping: Record<Profile, Locale> = {
  mmde: Locale.deDE,
  sede: Locale.deDE,
  mmes: Locale.esES,
  mmat: Locale.deAT,
  mmnl: Locale.nlNL,
  mmbe: Locale.nlBE, // Currently only dutch is supported
};

export const profileBotNameMapping: Record<Profile, BotName> = {
  mmde: BotName.Emmi,
  sede: BotName.Sammy,
  mmes: BotName.Emmi,
  mmat: BotName.Emmi,
  mmnl: BotName.Emmi,
  mmbe: BotName.Emmi,
};

export const profileCallCenterTimeZoneMapping: Record<Profile, TimeZone> = {
  mmde: TimeZone.EurBerlin,
  sede: TimeZone.EurBerlin,
  mmes: TimeZone.EurVienna,
  mmat: TimeZone.EurVienna,
  mmnl: TimeZone.EurBerlin,
  mmbe: TimeZone.EurBerlin,
};

export const profileShowContactCardIndicatorMapping: Record<Profile, boolean> =
  {
    mmde: true,
    sede: true,
    mmes: false,
    mmat: true,
    mmnl: true,
    mmbe: true,
  };

export const profileShowContactCardPhoneNumberMapping: Record<
  Profile,
  boolean
> = {
  mmde: true,
  sede: true,
  mmes: false,
  mmat: true,
  mmnl: true,
  mmbe: true,
};

export const profileSalesLineLabelMapping: Record<Profile, SalesLineLabel> = {
  mmde: SalesLineLabel.MediaMarkt,
  sede: SalesLineLabel.Saturn,
  mmes: SalesLineLabel.MediaMarkt,
  mmat: SalesLineLabel.MediaMarkt,
  mmnl: SalesLineLabel.MediaMarkt,
  mmbe: SalesLineLabel.MediaMarkt,
};

export const profileCountryMapping: Record<Profile, Country> = {
  mmde: Country.Germany,
  sede: Country.Germany,
  mmes: Country.Spain,
  mmat: Country.Austria,
  mmnl: Country.Netherlands,
  mmbe: Country.Belgium,
};

export const profilePrefixMapping: Record<ProfilePrefix, Profile[]> = groupBy(
  (profile) => profile.substring(0, 2),
  Object.values(Profile).sort(),
);

export const profiles = Object.keys(Profile).sort() as Profile[];

export const channels = profiles.map((profile) => profileToChannel(profile));
